import { render, staticRenderFns } from "./MobileAppScreen.vue?vue&type=template&id=49e03cf1&scoped=true&"
var script = {}
import style0 from "./MobileAppScreen.vue?vue&type=style&index=0&id=49e03cf1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e03cf1",
  null
  
)

export default component.exports